<template>
  <v-container class="pt-0">
    <v-card elevation="0">
      <v-card-title class="pb-0">
        {{ $t("courierSet.setData").toUpperCase() }}
      </v-card-title>
      <v-container>
        <v-row class="pb-5">
          <v-divider />
        </v-row>
        <v-row>
          <v-col class="pb-0">
            <text-field
              v-model="configurationName"
              :title="$t('courierSet.configurationName')"
              rules="required"
              @input="update"
            />
          </v-col>
          <v-col class="pb-0">
            <text-field
              v-model="id"
              :title="$t('courierSet.configurationId')"
              rules="required"
              :disabled="true"
              @input="update"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <select-field
              v-model="courierCode"
              :title="$t('courierSet.deliverySpeditor')"
              rules="required"
              :filed-items="SHIPMENT_METHOD_CODES"
              disabled
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-card
      elevation="0"
      class="mt-5"
    >
      <v-card-title class="pb-0">
        {{ $t("courierSet.authData").toUpperCase() }}
      </v-card-title>
      <v-container>
        <v-row class="pb-5">
          <v-divider />
        </v-row>
        <v-row>
          <v-col class="pb-0">
            <text-field
              v-model="apiKey"
              :title="$t('courierSet.apiKey')"
              rules="required"
              @input="update"
            />
          </v-col>
          <v-col class="pb-0">
            <text-field
              v-model="password"
              :title="$t('courierSet.apiSecret')"
              type="password"
              rules="required"
              @input="update"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0">
            <text-field
              v-model="url"
              :title="$t('courierSet.urlAddress')"
              :rules="'required|url'"
              @input="update"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-card
      elevation="0"
      class="mt-5"
    >
      <v-card-title class="pb-0">
        {{ $t("courierSet.pickup").toUpperCase() }}
      </v-card-title>
      <v-container>
        <v-row class="pb-5">
          <v-divider />
        </v-row>
        <v-row>
          <v-col class="pb-0">
            <select-field
              v-model="pickupType"
              :title=" $t('courierSet.pickupType')"
              rules=""
              :filed-items="pickupTypes"
              @input="update"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import TextField from '@/components/inputs/TextField.vue';
import SelectField from '@/components/inputs/SelectField.vue';
import { pickupTypes } from '@/constants/Speditors/DHLExpress/PickupTypes';
import { SHIPMENT_METHOD_CODES } from '@/constants/ShipmentMethodCodes.js';

import { v4 as uuidv4 } from 'uuid';
import { mapGetters } from 'vuex';

export default {
  components: {
    TextField, SelectField,
  },
  props: {
    isNew: { type: Boolean, default: false },
  },
  data() {
    return {
      SHIPMENT_METHOD_CODES,
      id: null,
      tenantId: null,
      configurationName: null,
      apiKey: null,
      password: null,
      url: null,
      pickupType: '',
      pickupTypes,
      courierCode: '',
    };
  },
  computed: {
    ...mapGetters('spedition', ['getConfigurationSet']),
  },
  mounted() {
    this.courierCode = 'dhl';
    if (!this.isNew) {
      this.getDataFromStore();
    } else {
      this.id = uuidv4();
      this.url = 'https://express.api.dhl.com/mydhlapi/test';
    }
  },
  methods: {
    update() {
      const serviceAuthorization = {
        clientEntityState: this.isNew ? 4 : 3,
        configurationName: this.configurationName,
        id: this.id,
        tenantId: this.tenantId,
        apiKey: this.apiKey,
        password: this.password,
        url: this.url,
        pickupType: this.pickupType,
      };
      this.$emit('@input', serviceAuthorization);
    },
    getDataFromStore() {
      const {
        id, configurationName, tenantId, password, url,
        apiKey,
      } = this.getConfigurationSet();
      this.id = id;
      this.tenantId = tenantId;
      this.configurationName = configurationName;
      this.password = password;
      this.apiKey = apiKey;
      this.password = password;
      this.url = url;
    },
  },
};
</script>

<style scoped lang="scss">
.col{
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>
