export const OutputImageProperties = {
  clientEntityState: 4,
  tenantId: '',
  id: '',
  customerBarcodes: [],
  customerLogos: [],
  encodingFormat: '',
  imageOptions: [],
  splitTransportAndWaybillDocLabels: false,
  allDocumentsInOneImage: false,
  splitDocumentsByPage: false,
  splitInvoiceAndReceipt: false,
  receiptAndLabelsInOneImage: false,

};
