<template>
  <v-container class="pt-0">
    <v-card elevation="0">
      <v-card-title class="pb-0">
        {{ $t("courierSet.payment").toUpperCase() }}
      </v-card-title>

      <v-container>
        <v-row class="pb-5">
          <v-divider />
        </v-row>
        <v-row>
          <v-col>
            <select-field
              v-model="typeCode"
              :title="$t('courierSet.shippingPaymentType')"
              :filed-items="typeCodes"
              rules="required"
              @input="update"
            />
          </v-col>
          <v-col>
            <text-field
              v-model="number"
              :title="$t('courierSet.billingAccountNumber')"
              :hint="$t('courierSet.billingAccountNumberHintDhlParcel')"
              rules="numeric|required"
              @input="update"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import TextField from '@/components/inputs/TextField.vue';
import SelectField from '@/components/inputs/SelectField.vue';
import { typeCodes } from '@/constants/Speditors/DHLExpress/TypeCodes';
import { mapGetters } from 'vuex';

export default {
  components: {
    TextField,
    SelectField,
  },
  props: {
    isNew: { type: Boolean, default: null },
  },
  data: () => ({
    typeCode: '',
    number: null,
    typeCodes,
  }),
  computed: {
    ...mapGetters('spedition', ['getConfigurationSet', 'getOrganization']),
  },
  watch: {
    isNew() {
      this.getDataFromStore();
    },
  },
  mounted() {
    if (!this.isNew) {
      this.getDataFromStore();
    }
  },
  methods: {
    update() {
      const billing = {
        clientEntityState: this.isNew ? 4 : 3,
        number: this.number,
        typeCode: this.typeCode,
      };
      this.$emit('@input', billing);
    },
    getDataFromStore() {
      const { dhlExpressDefaultValues } = this.getConfigurationSet();
      const { accounts } = dhlExpressDefaultValues;
      this.typeCode = accounts[0].typeCode;
      this.number = accounts[0].number;
    },
  },
};
</script>

<style scoped lang="scss">
.v-select{
  padding: 0px 10px 0px 10px;
}
.col{
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>
