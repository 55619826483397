import { mapGetters, mapState } from 'vuex';

export default {
  computed: {
    ...mapGetters('spedition', ['getConfigurationSet', 'getOrganization']),
    ...mapState('spedition', ['configSet']),
  },
  watch: {
    configSet() {
      /** TODO: remove after store refactor  */
      this.getDataFromStore();
    },
  },
  mounted() {
    if (!this.isNew) {
      this.getDataFromStore();
    }
    this.tenantId = this.getOrganization().id;
    this.unchangedSet = [...this.additionalServices];
  },
  methods: {
    getDataFromStore() {
      const { additionalServices } = this.getConfigurationSet();
      this.additionalServices = additionalServices ? additionalServices.services : [];
      this.id = additionalServices ? additionalServices.id : null;
    },
    deactivate(service) {
      this.additionalServices.find(
        (x) => x.code === service.serviceId || x.code === service.id,
      ).clientEntityState = 2;
      // tutaj tez id serwisu
      const ser = this.additionalServices.find(
        (x) => x.code === service.serviceId || x.code === service.id,
      );
      if (!ser.id) {
        const idx = this.additionalServices.indexOf(ser);
        this.additionalServices.splice(idx, 1);
      }
      this.update();
      this.clearAdditionalData(service);
    },
    activate({
      isActive, code, isVisible,
    }) {
      const changedService = this.additionalServices.find(
        (x) => x.code === this.getServiceId(code) || x.code === code,
      );
      if (changedService && changedService.id) {
        const idx = this.additionalServices.indexOf(changedService);
        this.additionalServices[idx].clientEntityState = 1;
      } else {
        this.additionalServices.push(
          {
            clientEntityState: 4,
            tenantId: this.tenantId,
            code,
            isActive,
            isVisible,
          },
        );
      }
      this.update();
    },
    updateService({
      isActive, code, id, isVisible,
    }) {
      this.additionalServices.find(
        (x) => x.code === code || x.code === id,
      ).isActive = isActive;
      this.additionalServices.find(
        (x) => x.code === code || x.code === id,
      ).isVisible = isVisible;
      const state = this.additionalServices.find(
        (x) => x.code === code || x.code === id,
      ).clientEntityState;
      this.additionalServices.find(
        (x) => x.code === code || x.code === id,
      )
        .clientEntityState = state === 4 ? state : 3;
      this.update();
    },
    isActive(service) {
      return this.additionalServices.find((x) => x.code === service.serviceId
            || x.code === service.id)?.isActive;
    },
    isVisible(service) {
      return this.additionalServices.find((x) => x.code === service.serviceId
            || x.code === service.id)?.isVisible;
    },
    isSelected(service) {
      return !!this.additionalServices.find((x) => x.code === service.serviceId
        || x.code === service.id)
        && this.additionalServices.find((x) => x.code === service.serviceId
        || x.code === service.id).clientEntityState !== 2;
    },
    clearAdditionalData() {
    },
    getServiceId(serviceId) {
      return this.courierAddtionalServices.find((item) => item.serviceId === serviceId)?.id;
    },
  },
};
